<template>
  <Card>
    <div class="row">
      <h5 class="mb-0">Benutzerkonto erstellen</h5>
      <h6 v-if="company" class="center grey-text text-upper" style="margin-top: 0.25rem">bei {{ company }}</h6>
    </div>

    <form @submit.prevent="register">
      <UserDataForm v-model="user" />

      <div class="input-field col s12">
        <button :disabled="busy" type="submit" class="btn waves-effect waves-light col s12">Registrieren</button>
      </div>
    </form>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
import UserDataForm from "@/components/UserDataForm.vue";
import { actions, pages } from "@/constants";

export default {
  name: "Register",
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: ""
      }
    };
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    company() {
      if (!this.token) return null;
      return this.$route.query.company;
    },
    token() {
      return this.$route.query.token;
    }
  },
  methods: {
    async register() {
      const data = Object.assign({ invitation: this.token }, this.user);
      const result = await this.$store.dispatch(actions.REGISTER, data);
      if (result === null) return;

      if (result.requireEmailConfirmation) {
        this.$router.push({
          path: pages.REGISTER_CONFIRM,
          query: { email: this.user.email }
        });
      }
      else {
        this.$toast.success("Ihr Account wurde erfolgreich erstellt. Sie können sich nun einloggen.");
        this.$router.push(pages.LOGIN);
      }
    }
  },
  components: {
    Card,
    UserDataForm
  }
};
</script>

<style module type="scss">

</style>
