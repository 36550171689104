<template>
  <div class="row">
    <InputField required v-model="user.firstName" id="firstName" label="Vorname" class="m6 mb-0" icon="account_circle" />
    <InputField required v-model="user.lastName" id="lastName" label="Nachname" class="m6 mb-0" />
  </div>

  <div class="row">
    <InputField required v-model="user.email" id="email" label="Email" class="my-0" icon="mail_outline" />
  </div>

  <div v-if="!noPassword" class="row">
    <InputField required v-model="user.password" id="password" label="Passwort" type="password" pattern=".{8,50}" class="my-0" icon="lock_outline" />
  </div>

  <div class="row">
    <InputField v-model="user.phone" id="phone" label="Telefonnummer (optional)" type="tel" pattern="^\+?[\d\-\(\) ]*$" class="my-0" icon="phone" />
  </div>
</template>

<script>
import { events } from "@/constants.js";
import InputField from "@/components/InputField.vue";

export default {
  name: "UserDataForm",
  emits: [events.UPDATE_MODEL_VALUE],
  data() {
    return {
      user: this.modelValue
    };
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    noPassword: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    user() {
      this.$emit(events.UPDATE_MODEL_VALUE);
    }
  },
  components: {
    InputField
  }
};
</script>

<style module lang="scss">

</style>
